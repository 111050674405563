// Fonts
$text-font-family: Merriweather, Georgia, serif;
$heading-font-family: Lato, Helvetica, sans-serif;
$code-font-family: 'Source Code Pro', 'Lucida Console', monospace;

// Colors
$bg-color: #FAFAFA !default;
$fg-color: #212121 !default;
$alt-bg-color: #E0E0E0 !default;
$alt-fg-color: #000 !default;
$link-color: #1565c0 !default;

// Colors inverted
$bg-color-inverted: #212121 !default;
$fg-color-inverted: #fafafa !default;
$alt-bg-color-inverted: #424242 !default;
$alt-fg-color-inverted: #fafafa !default;
$link-color-inverted: #f44336 !default;
